<template>
  <div class="userView">
    <div class="user_header">
      <van-sticky>
        <van-nav-bar
          :title="this.$store.getters.userinfo.inviteParent ? $t('我的上级') + ':' + this.$store.getters.userinfo.inviteParent : $t('会员中心')"
        ></van-nav-bar>
      </van-sticky>
      <div class="userinfo">
        <span class="my-avatar">
          <img :src="avatar" />
        </span>
        <div class="info">
          <h1>{{ $t('登录账号') }}: {{ this.$store.getters.userinfo.mobile }}</h1>
          <span>{{ $t('邀请码') }}: {{ this.$store.getters.userinfo.inviteCode }}</span>
        </div>
        <div class="logout">
          <a href="javascript:;" @click="onLogOut">
            <img src="@/assets/images/logout.png" height="30" />
            <p>{{ $t('退出登录') }}</p>
          </a>
        </div>
      </div>
    </div>

    <div class="consign_items">
      <div class="menu_title">
        <span>
          {{ $t('余额') }}:
          <em>{{ this.$store.getters.userinfo.balance }}</em>
          {{ $t('金币') }}
        </span>
        <van-button type="info" size="small" @click="toWallet">{{ $t('我的钱包') }}</van-button>
      </div>
      <van-steps :active="active" active-color="#38f">
        <van-step>{{ $t('封号') }}</van-step>
        <van-step>{{ $t('限制') }}</van-step>
        <van-step>{{ $t('良好') }}</van-step>
        <van-step>{{ $t('优秀') }}</van-step>
      </van-steps>
    </div>

    <div class="user_balance">
      <ul>
        <div class="databox">
          <li>
            <em>{{ $t('个人余额') }}(円)</em>
            {{ this.$store.getters.userinfo.balance }}
          </li>
        </div>
        <div class="databox">
          <li>
            <em>{{ $t('今日收益') }}(円)</em>
            {{ today_profit }}
          </li>
        </div>
        <div class="databox">
          <li>
            <em>{{ $t('昨日收益') }}(円)</em>
            {{ last_profit }}
          </li>
        </div>
      </ul>
      <ul>
        <div class="databox">
          <li>
            <em>{{ $t('本月收益') }}(円)</em>
            {{ month_profit }}
          </li>
        </div>
        <div class="databox">
          <li>
            <em>{{ $t('本周收益') }}(円)</em>
            {{ week_profit }}
          </li>
        </div>
        <div class="databox">
          <li>
            <em>{{ $t('总收益') }}(円)</em>
            {{ total_profit }}
          </li>
        </div>
      </ul>
      <ul>
        <div class="databox">
          <li>
            <em>{{ $t('今日完成任务') }}({{ $t('单') }})</em>
            {{ this.$store.getters.userinfo.today_over_task }}
          </li>
        </div>
        <div class="databox">
          <li>
            <em>{{ $t('今日剩余任务') }}({{ $t('单') }})</em>
            {{ this.$store.getters.userinfo.today_surplus_task }}
          </li>
        </div>
      </ul>
    </div>
    <van-grid :column-num="2" class="mygrid">
      <van-grid-item @click="toTask()">
        <img src="@/assets/images/record_list.png" />
        <span class="text">{{ $t('任务记录') }}</span>
      </van-grid-item>
      <van-grid-item @click="toAuditRecord()">
        <img src="@/assets/images/audit.png" />
        <span class="text">{{ $t('审核任务') }}</span>
      </van-grid-item>
    </van-grid>
    <van-grid :column-num="3" square :gutter="5" class="mygrid">
      <van-grid-item @click="toSetting">
        <img src="@/assets/images/center_001.png" />
        <span class="text">{{ $t('个人信息') }}</span>
      </van-grid-item>
      <van-grid-item @click="toBlindAccount">
        <img src="@/assets/images/center_002.png" />
        <span class="text">{{ $t('账号绑定') }}</span>
      </van-grid-item>
      <van-grid-item @click="toDayReport">
        <img src="@/assets/images/center_003.png" />
        <span class="text">{{ $t('日结报表') }}</span>
      </van-grid-item>
      <van-grid-item @click="MoneyLogHandle">
        <img src="@/assets/images/center_004.png" />
        <span class="text">{{ $t('账变记录') }}</span>
      </van-grid-item>
      <van-grid-item @click="toInvite">
        <img src="@/assets/images/center_005.png" />
        <span class="text">{{ $t('邀请好友') }}</span>
      </van-grid-item>
      <van-grid-item @click="toTeamReport">
        <img src="@/assets/images/center_009.png" />
        <span class="text">{{ $t('团队报表') }}</span>
      </van-grid-item>
      <van-grid-item @click="toCredit">
        <img src="@/assets/images/center_012.png" />
        <span class="text">{{ $t('信用中心') }}</span>
      </van-grid-item>
      <van-grid-item @click="toProfit">
        <img src="@/assets/images/center_107.png" />
        <span class="text">{{ $t('收益宝') }}</span>
      </van-grid-item>      
      <van-grid-item @click="downHandler">
        <img src="@/assets/images/index_select.png" />
        <span class="text">{{ $t('公司简介') }}</span>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import { saveToLocal } from "@/utils/local-storage";
import { logout, getProfit } from "@/api/users";
import { getDownUrl } from "@/api/utils";

export default {
  data() {
    return {
      avatar: require("@/assets/images/head_" +
        this.$store.getters.userinfo.avatar +
        ".png"),
      myprofit: [],
      today_profit: 0,
      today_percentage: 0,
      today_point: 0,
      last_profit: 0,
      week_profit: 0,
      month_profit: 0,
      last_month_profit: 0,
      total_profit: 0,
      app_download_url: ""
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.GetProfit();
    this.GetDownUrl();
  },
  computed: {
    active: {
      get() {
        if (
          this.$store.getters.userinfo.credit >= 0 &&
          this.$store.getters.userinfo.credit < 50
        ) {
          return this.$store.getters.userinfo.credit / 50;
        }
        if (
          this.$store.getters.userinfo.credit >= 50 &&
          this.$store.getters.userinfo.credit < 60
        ) {
          return (this.$store.getters.userinfo.credit - 50) / 10 + 1;
        }
        if (this.$store.getters.userinfo.credit >= 60) {
          return (this.$store.getters.userinfo.credit - 60) / 40 + 2;
        }
      },
      set(value1) {}
    }
  },
  methods: {
    downHandler() {
      window.open("https://www.kyotoanimation.co.jp/");
    },
    GetDownUrl() {
      getDownUrl({})
        .then(response => {
          this.app_download_url = response.result;
        })
        .catch(error => {});
    },
    //获取收益详情
    GetProfit() {
      getProfit({ id: this.id })
        .then(response => {
          this.myprofit = response.result;
          this.today_profit = response.result.today_profit;
          this.today_percentage = response.result.today_percentage;
          this.today_point = response.result.today_point;
          this.last_profit = response.result.last_profit;
          this.week_profit = response.result.week_profit;
          this.month_profit = response.result.month_profit;
          this.last_month_profit = response.result.last_month_profit;
          this.total_profit = response.result.total_profit;
        })
        .catch(error => {});
    },
    MoneyLogHandle() {
      this.$router.push("/main/user/money_log");
    },
    onLogOut() {
      this.$dialog
        .confirm({
          title: this.$t("提示"),
          message: this.$t("确定退出登录吗?"),
          confirmButtonText: this.$t("确定"),
          cancelButtonText: this.$t("取消")
        })
        .then(() => {
          logout({})
            .then(response => {
              this.$toast({
                message: response.msg,
                duration: 500,
                forbidClick: true
              });
              setTimeout(() => {
                saveToLocal("token", "");
                this.$router.replace("/login");
              }, 500);
            })
            .catch(error => {});
        })
        .catch(() => {});
    },
    toSetting() {
      this.$router.push("/main/user/setting");
    },
    toWallet() {
      this.$router.push("/main/user/wallet");
    },
    toTask() {
      this.$router.push("/main/task");
    },
    toInvite() {
      this.$router.push("/main/user/invite");
    },
    toDayReport() {
      this.$router.push("/main/user/dayReport");
    },
    toUserInfo() {
      this.$router.push("/main/user/userinfo");
    },
    toHelp() {
      this.$router.push("/main/user/help");
    },
    toCredit() {
      this.$router.push("/main/user/credit");
    },
    toAppointmentGoods() {
      this.$router.push("/main/user/my_appointment_goods");
    },
    toBlindAccount() {
      this.$router.push("/main/user/blindAccount");
    },
    toTeamReport() {
      this.$router.push("/main/user/teamReport");
    },
    toAuditRecord() {
      this.$router.push("/main/user/auditRecord");
    },
    toProfit(){
       this.$router.push("/main/user/lc");
    }
  }
};
</script>
<style lang="stylus">
.userView
  .user_balance
    margin-bottom: 0.3rem
    color: #4087f1
    font-weight: 600
    ul
      display: flex
      text-align: center
      font-size: 0.42rem
      line-height: 1
      margin-bottom: 1px
      .databox:last-child
        padding: 0
      .databox
        flex: 1
        padding-right: 1px
        li
          color: #4087f1
          background-color: #151d31
          flex-direction: column
          box-sizing: border-box
          padding: 0.5rem 0.2rem
          height: 100%
          em
            display: block
            line-height: 1
            margin-bottom: 0.3rem
            color: #bbb
            font-size: 0.38rem
            font-weight: 400
    .van-col
      border-right: 1px solid $whiteTextColor
      color: $whiteTextColor
    .van-col:last-child
      border-right: 0
  .user_nav.van-hairline--bottom::after
    border: 0
  .user_header
    padding: 0.1rem 0 0 0
    margin-top: -0.1rem
    .head_message
      position: absolute
      top: 0.5rem
      right: 0.64rem
      display: flex
      justify-content: space-between
    .userinfo
      display: flex
      width: 100%
      background-color: #151d31
      padding: 0.2rem 0
      align-items: center
      flex: 1
      .info
        color: #ffffff
        line-height: 0.6rem
        h1
          font-size: 0.38rem
          font-weight: 400
          line-height: 0.6rem
        span
          font-size: 0.38rem
          height: 0.683rem
          background: rgba(0, 0, 0, 0.1)
          border-radius: 0.341rem
          text-align: center
    .logout
      display: flex
      justify-content: center
      align-items: center
      text-align: center
      margin-left: 0.8rem
      p
        color: #fff
        font-size: 0.36rem
    .van-col--3
      display: flex
      align-items: center
      justify-content: center
      height: 1.78rem
    .my-avatar
      display: inline-block
      margin-left: 0.3rem
      padding: 0.06rem
      border-radius: 0.9rem
      margin-right: 0.2rem
      img
        display: block
        width: 1.63rem
        height: 1.63rem
        border-radius: 0.9rem
  .consign_items
    border-radius: 0.2133rem !important
    background: #fff
    overflow: hidden
    margin: 0 4% 0
    .van-step--finish .van-step__circle, .van-step--finish .van-step__line
      background-color: #38f
    .menu_title
      display: flex
      justify-content: space-between
      padding-right: 0.427rem
      position: relative
      height: 0.7rem
      line-height: 0.7rem
      color: #bbb
      font-size: 0.36rem
      padding: 0.3rem 0.5rem 0.3rem 0.5rem
      align-items: center
      border-radius: 0.213rem 0.213rem 0 0
      font-weight: 500
      em
        font-size: 0.6rem
        color: #4087f1
      .van-button
        height: 0.7rem
        line-height: 0.66667rem
        border-radius: 0.15rem
      .balance
        font-size: 0.36rem
        color: #d21a06
        font-weight: 500
  .mygrid
    margin-bottom: 0.3rem
    .van-grid-item__content
      background-color: #151d31 !important
    img
      height: 0.8rem
      display: block
      margin: 0 auto
    .text
      font-size: 0.34rem
      color: #fff
      text-align: center
      margin-top: 0.3rem
</style>
