import request from '@/utils/request';
import Vue from 'vue';

//修改登录密码
export function rePassword(data) {
  return request({
    url: '/users/repassword?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}
//修改二级密码
export function rePaypwd(data) {
  return request({
    url: '/users/repaypwd?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}
//设置二级密码
export function setPaypwd(data) {
  return request({
    url: '/users/setpaypwd?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}
//退出登录
export function logout() {
  return request({
    url: '/users/logout?lang=' + Vue.prototype.$lang,
    method: 'post',
  });
}
//修改手机号码
export function reMobile(data) {
  return request({
    url: '/users/remobile?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//购买记录
export function profitListView(data) {
  return request({
    url: '/users/profitListView?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}


//卖出列表
export function get_sell_list(data) {
  return request({
    url: '/withdraw/get_withdraw_lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//设置头像
export function set_avatar(data) {
  return request({
    url: '/users/set_avatar?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//客服二维码
export function getKefuInfo(data) {
  return request({
    url: '/Home/getKefuInfo?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//个人资料
export function set_userinfo(data) {
  return request({
    url: '/users/set_userinfo?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function setAccount(data) {
  return request({
    url: '/users/setAccount?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}

//币明细
export function MoneyLog(data) {
  return request({
    url: '/Money/get_money_log?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//团队列表
export function getTeam(data) {
  return request({
    url: '/users/getTeam?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//转账
export function transferMoney(data) {
  return request({
    url: '/Transfer/transferMoney?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//资料认证
export function set_idendity_info(data) {
  return request({
    url: '/users/set_idendity_info?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}

//余额兑换
export function exchange(data) {
  return request({
    url: '/Transfer/moneyToMoney1?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//降级
export function cancelLevel(data) {
  return request({
    url: '/users/cancelLevel?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}

//升级
export function upLevel(data) {
  return request({
    url: '/users/upLevel?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}
//获取收益详情
export function getProfit(data) {
  return request({
    url: '/users/profit?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//设置头像
export function setAvatar(data) {
  return request({
    url: '/users/setAvatar?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}

//添加收益
export function setProfit(data) {
  return request({
    url: '/users/setProfit?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}



//绑定账号
export function blindAccount(data) {
  return request({
    url: '/users/blindAccount?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}
