import request from '@/utils/request';
import Vue from 'vue';

export function getDownUrl(data) {
  return request({
    url: '/utils/getDownUrl?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function getProfit(data) {
  return request({
    url: '/utils/getProfit?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//获取会员等级列表
export function getLevel(data) {
  return request({
    url: '/utils/getLevel?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//获取奖励的用户
export function getLucyList(data) {
  return request({
    url: '/utils/getLucyList?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//获取任务分类
export function getTaskType(data) {
  return request({
    url: '/utils/getTaskType?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//获取玩家任务完成单数
export function getUserShow(data) {
  return request({
    url: '/utils/getUserShow?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//商家任务完成单数
export function getBrandShow(data) {
  return request({
    url: '/utils/getBrandShow?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//获取验证图片
export function getCheckImage(data) {
  return request({
    url: '/utils/getCheckImage?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
